<template>
  <div>
    <ol class="organizational-chart" style="background: #fff;">
      <li>
        <div>
          <p>พระที่นั่งอัมพรสถาน</p>
        </div>
        <ol>
          <li
            v-for="building in graphData"
            :key="building.id_location_building"
          >
            <div>
              <p>{{ building.name }}</p>
            </div>
            <ol>
              <li
                v-for="floor in building.floors"
                :key="floor.id_location_floor"
              >
                <div
                  :class="{ 'cursor-pointer': floor.rooms.length > 0 }"
                  @click="
                    floor.rooms.length > 0
                      ? (floor.show = !floor.show)
                      : (floor.show = false)
                  "
                >
                  <p>{{ floor.name }}</p>
                </div>
                <ol v-if="floor.show">
                  <li v-for="room in floor.rooms" :key="room.id_location_room">
                    <!-- <div
                        class="cursor-pointer"
                        @click="
                          showAssetFromRoom(building.building, floor.floor, room)
                        "
                      >
                        <p>{{ room.room }}</p>
                      </div> -->
                    <div
                      :class="{ 'cursor-pointer': room.categories.length > 0 }"
                      @click="
                        room.categories.length > 0
                          ? (room.show = !room.show)
                          : (room.show = false)
                      "
                    >
                      <p>{{ room.name }}</p>
                    </div>
                    <ol v-if="room.show">
                      <li
                        v-for="category in room.categories"
                        :key="category.id_category"
                      >
                        <div
                          :class="{
                            'cursor-pointer':
                              category.sub_categories.length > 0,
                          }"
                          @click="
                            category.sub_categories.length > 0
                              ? (category.show = !category.show)
                              : (category.show = false)
                          "
                        >
                          <p>{{ category.name }}</p>
                        </div>
                        <ol v-if="category.show">
                          <li
                            v-for="subCategory in category.sub_categories"
                            :key="subCategory.id_sub_category"
                          >
                            <div
                              class="cursor-pointer"
                              @click="
                                showAssetFromRoom({
                                  buildingId: building.id_location_building,
                                  building: building.name,
                                  floorId: floor.id_location_floor,
                                  floor: floor.name,
                                  roomId: room.id_location_room,
                                  room: room.name,
                                  categoryId: category.id_category,
                                  category: category.name,
                                  subCategoryId: subCategory.id_sub_category,
                                  subCategory: subCategory.name,
                                })
                              "
                            >
                              <p>{{ subCategory.name }}</p>
                            </div>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
// import hoshi from "../../../assets/mock/Hoshi3.json";
// import categories from "../../../assets/mock/HoshiCategories.json";
import { mapGetters } from "vuex";
export default {
  props: ["graphData"],
  // data() {
  //   return {
  //     hoshi: hoshi.map((i) => ({
  //       ...i,
  //       floors: i.floors.map((j) => ({
  //         ...j,
  //         rooms: j.rooms.map((k) => ({
  //           ...k,
  //           show: false,
  //           categories: categories.map((l) => ({
  //             ...l,
  //             show: false,
  //           })),
  //         })),
  //         show: false,
  //       })),
  //     })),
  //   };
  // },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      companyName: "companyName",
    }),
  },
  methods: {
    showAssetFromRoom({
      buildingId,
      building,
      floorId,
      floor,
      roomId,
      room,
      categoryId,
      category,
      subCategoryId,
      subCategory,
    }) {
      this.$router.push({
        name: "diagram-assets",
        query: {
          bid: buildingId,
          bn: building,
          fid: floorId,
          fn: floor,
          rid: roomId,
          rn: room,
          cid: categoryId,
          cn: category,
          scid: subCategoryId,
          scn: subCategory,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
p {
  color: #fff;
  text-align: center;
  word-break: break-word;
}

ol.organizational-chart,
ol.organizational-chart ol,
ol.organizational-chart li,
ol.organizational-chart li > div {
  position: relative;
}

ol.organizational-chart,
ol.organizational-chart ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

ol.organizational-chart {
  text-align: center;
}

ol.organizational-chart ol {
  padding-top: 1em;
}

ol.organizational-chart ol:before,
ol.organizational-chart ol:after,
ol.organizational-chart li:before,
ol.organizational-chart li:after,
ol.organizational-chart > li > div:before,
ol.organizational-chart > li > div:after {
  background-color: #b7a6aa;
  content: "";
  position: absolute;
}

ol.organizational-chart ol > li {
  padding: 1em 0 0 1em;
}

ol.organizational-chart > li ol:before {
  height: 1em;
  left: 50%;
  top: 0;
  width: 3px;
}

ol.organizational-chart > li ol:after {
  height: 3px;
  left: 3px;
  top: 1em;
  width: 50%;
}

ol.organizational-chart > li ol > li:not(:last-of-type):before {
  height: 3px;
  left: 0;
  top: 2em;
  width: 1em;
}

ol.organizational-chart > li ol > li:not(:last-of-type):after {
  height: 100%;
  left: 0;
  top: 0;
  width: 3px;
}

ol.organizational-chart > li ol > li:last-of-type:before {
  height: 3px;
  left: 0;
  top: 2em;
  width: 1em;
}

ol.organizational-chart > li ol > li:last-of-type:after {
  height: 2em;
  left: 0;
  top: 0;
  width: 3px;
}

ol.organizational-chart li > div {
  background-color: #f5a68d;
  border-radius: 3px;
  min-height: 2em;
  padding: 0.5em;
}

/*** PRIMARY ***/
ol.organizational-chart > li > div {
  background-color: #2634ba;
  margin-right: 1em;
}

ol.organizational-chart > li > div:before {
  bottom: 2em;
  height: 3px;
  right: -1em;
  width: 1em;
}

ol.organizational-chart > li > div:first-of-type:after {
  bottom: 0;
  height: 2em;
  right: -1em;
  width: 3px;
}

ol.organizational-chart > li > div + div {
  margin-top: 1em;
}

ol.organizational-chart > li > div + div:after {
  height: calc(100% + 1em);
  right: -1em;
  top: -1em;
  width: 3px;
}

/*** SECONDARY ***/
ol.organizational-chart > li > ol:before {
  left: inherit;
  right: 0;
}

ol.organizational-chart > li > ol:after {
  left: 0;
  width: 100%;
}

ol.organizational-chart > li > ol > li > div {
  background-color: #9266ef;
  min-width: 230px;
}

/*** TERTIARY ***/
ol.organizational-chart > li > ol > li > ol > li > div {
  background-color: #5b6cff;
  min-width: 230px;
}

/*** QUATERNARY ***/
ol.organizational-chart > li > ol > li > ol > li > ol > li > div {
  background-color: #6bc4f6;
  min-width: 200px;
}

/*** QUINARY ***/
ol.organizational-chart > li > ol > li > ol > li > ol > li > ol > li > div {
  background-color: #74ce88;
}

/*** MEDIA QUERIES ***/
@media only screen and (min-width: 64em) {
  ol.organizational-chart {
    // margin-left: -1em;
    // margin-right: -1em;
  }

  /* PRIMARY */
  ol.organizational-chart > li > div {
    display: inline-block;
    float: none;
    margin: 0 1em 1em 1em;
    vertical-align: bottom;
  }

  ol.organizational-chart > li > div:only-of-type {
    margin-bottom: 0;
    width: calc((100% / 1) - 2em - 4px);
  }

  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(2),
  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(2) ~ div {
    width: calc((100% / 2) - 2em - 4px);
  }

  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(3),
  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(3) ~ div {
    width: calc((100% / 3) - 2em - 4px);
  }

  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(4),
  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(4) ~ div {
    width: calc((100% / 4) - 2em - 4px);
  }

  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(5),
  ol.organizational-chart > li > div:first-of-type:nth-last-of-type(5) ~ div {
    width: calc((100% / 5) - 2em - 4px);
  }

  ol.organizational-chart > li > div:before,
  ol.organizational-chart > li > div:after {
    bottom: -1em !important;
    top: inherit !important;
  }

  ol.organizational-chart > li > div:before {
    height: 1em !important;
    left: 50% !important;
    width: 3px !important;
  }

  ol.organizational-chart > li > div:only-of-type:after {
    display: none;
  }

  ol.organizational-chart > li > div:first-of-type:not(:only-of-type):after,
  ol.organizational-chart > li > div:last-of-type:not(:only-of-type):after {
    bottom: -1em;
    height: 3px;
    width: calc(50% + 1em + 3px);
  }

  ol.organizational-chart > li > div:first-of-type:not(:only-of-type):after {
    left: calc(50% + 3px);
  }

  ol.organizational-chart > li > div:last-of-type:not(:only-of-type):after {
    left: calc(-1em - 3px);
  }

  ol.organizational-chart > li > div + div:not(:last-of-type):after {
    height: 3px;
    left: -2em;
    width: calc(100% + 4em);
  }

  /* SECONDARY */
  ol.organizational-chart > li > ol {
    display: flex;
    flex-wrap: nowrap;
  }

  ol.organizational-chart > li > ol:before,
  ol.organizational-chart > li > ol > li:before {
    height: 1em !important;
    left: 50% !important;
    top: 0 !important;
    width: 3px !important;
  }

  ol.organizational-chart > li > ol:after {
    display: none;
  }

  ol.organizational-chart > li > ol > li {
    flex-grow: 1;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
  }

  ol.organizational-chart > li > ol > li:only-of-type {
    padding-top: 0;
  }

  ol.organizational-chart > li > ol > li:only-of-type:before,
  ol.organizational-chart > li > ol > li:only-of-type:after {
    display: none;
  }

  ol.organizational-chart > li > ol > li:first-of-type:not(:only-of-type):after,
  ol.organizational-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    height: 3px;
    top: 0;
    width: 50%;
  }

  ol.organizational-chart
    > li
    > ol
    > li:first-of-type:not(:only-of-type):after {
    left: 50%;
  }

  ol.organizational-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    left: 0;
  }

  ol.organizational-chart > li > ol > li + li:not(:last-of-type):after {
    height: 3px;
    left: 0;
    top: 0;
    width: 100%;
  }
}
</style>
