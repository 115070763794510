<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <div>
      <div class="back-icon">
        <img
          src="../../../assets/previous.svg"
          width="30"
          @click="$router.back()"
        />
      </div>
      <div class="greeting">
        <h2>{{ companyName }}</h2>
        <h3>
          ({{
            branchName === "Head Office"
              ? $t("selectcompany.headoff")
              : branchName
          }})
        </h3>
      </div>
      <div
        class="p-3 m-3 overflow-hidden"
        style="min-height: 600px; box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15); border-radius: 20px;"
      >
        <div class="overflow-scroll" style="min-height: 600px;">
          <org-chart :graphData="mappedLocations"></org-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import OrgChart from "../../../components/CustomComponent/Hoshi/OrgChart.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import { authHeader } from "../../../store/actions";
import baseUrl from "../../../util/backend";

export default {
  data() {
    return {
      id_company: null,
      categoryData: [],
      locationData: [],
      mappedLocations: null,
    };
  },

  computed: {
    ...mapGetters({
      companyName: "companyName",
      branchName: "branchName",
    }),
  },
  methods: {
    async getNestedLocation() {
      const res = await axios.get(`${baseUrl()}location/nestedLocation`, {
        params: {
          id_company: this.id_company,
        },
        ...authHeader(),
      });
      this.locationData = res.data;
    },
    async getCategories() {
      const options = await this.$store.dispatch("loadOptionChoice");
      this.categoryData = options.optionCategory;
    },
    async loadPageData() {
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
      await this.getNestedLocation();
      await this.getCategories();
      this.mappedLocations = this.locationData.map((i) => {
        return {
          ...i,
          floors: i.location_floors.map((j) => {
            return {
              ...j,
              show: false,
              rooms: j.location_rooms.map((k) => {
                return {
                  ...k,
                  show: false,
                  categories: this.categoryData.map((l) => {
                    return {
                      ...l,
                      show: false,
                    };
                  }),
                };
              }),
            };
          }),
        };
      });
    },
  },
  created() {
    this.loadPageData();
  },

  components: { Footer, OrgChart },
};
</script>

<style lang="scss" scoped>
.greeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 1rem;
  height: 173px;
  // background: transparent
  //   linear-gradient(180deg, var(--unnamed-color-007AFE) 0%, #013F81 100%) 0% 0%
  //   no-repeat padding-box;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);

  h2 {
    font-size: 32px;
    font-weight: 700;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // @media screen and (min-width: 992px) {
    //   font-size: 24px;
    // }
  }

  h3 {
    font-size: 20px;
    // font-size: 14px;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // @media screen and (min-width: 992px) {
    //   font-size: 20px;
    // }
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}
</style>
